import React from 'react';
import { BsInstagram,BsArrowLeftShort,BsArrowRightShort } from 'react-icons/bs';

import { images} from '../../constants';
import { SubHeading } from '../../components';
import './Gallery.css';

const galleryImages = [images.gallery01, images.gallery02, images.gallery03, images.gallery04];

const Gallery = () => {
  const scrollRef = React.useRef(null);
  const scroll= (direction)=>{
    const {current} = scrollRef;
    if(direction==="left"){
      current.scrollLeft -=300;
    }else{
      current.scrollLeft +=300;
    }

  }


return(
  <div className='app__gallery flex__center'>
    <div className='app__gallery-content'>
      <SubHeading title="Instagram" />
      <h1 className='headtext__cormorant'>Gallery Photo</h1>
      <p className='p__opensans' style={{color:'#AAA' , marginTop:"2rem"}}>To see more our plate you can visit our social media like facebook and Instagram, don,t gorget to subscribe in our page</p>
      <button type='button' className='custom__button'>View more</button>
    </div>
    <div className='app__gallery-images'>
      <div className='app__gallery-images_container' ref={scrollRef}>
        {galleryImages.map((image, index) =>(
          <div className='app__gallery-images_card flex__center' key={`gallerry_images-${index +1}`}>
            <img src={image} alt="gallery images"/>
            <BsInstagram className='gallery__image-icon' />
          </div>
        ))}
      </div>
      <div className='app__gallery-images_arrows'>
        <BsArrowLeftShort className="gallery__arrow-icon" onClick={()=>scroll('left')} />
        <BsArrowRightShort className="gallery__arrow-icon" onClick={()=>scroll('right')} />
      </div>
    </div>
    
  </div>
);
}
export default Gallery;
