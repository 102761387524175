import React from 'react';
import { SubHeading } from '../../components';

import { images } from '../../constants';

import './Chef.css';

const Chef = () => (
  <div className='app__bg app__wrapper section__padding'>
    <div className='app__wrapper_img app__wrapper_img-reverse'>
      <img src={images.chef} alt="image chef" />
    </div>
    <div className='app__wrapper_info'>
      <SubHeading title="Med-Amine chef"/>
      <h1 className='headtext__cormorant'>Our believe in </h1>
      <div className='app_chef-content'>
        <div className='app__chef-content_quote'>
          <img src= {images.quote} alt="chef quote" />
          <p className='p__opensans'>We believe that every thing from nature, is good for our heath, so eat bio and drink bio for longlife. </p>

        </div>
        <p className='p__opensans'> let ous chef hep you for discover and live this moment</p>
      </div>
      <div className='app__chef-sign'>
        <p> Aboubaker Gued</p>
        <p className='p__opensans'>Founder and Chef</p>
        <img src={images.sign} alt="sign chef" />


      </div>
    </div>
  </div>
);

export default Chef;
