import images from './images';

const wines = [
  {
    title: 'Hamoud boualem Bida',
    price: '$56',
    tags: 'ALG | Bottle',
  },
  {
    title: 'Hamoud boualem Soda',
    price: '$59',
    tags: 'Jijel | Bottle',
  },
  {
    title: 'Hamoud boualem Orange',
    price: '$44',
    tags: 'Blida | 750 ml',
  },
  {
    title: 'Hamoud boualem Citron',
    price: '$31',
    tags: 'Ras elbour  | 750 ml',
  },
  {
    title: 'Hamoud boualem fraise',
    price: '$26',
    tags: 'Tireche | 750 ml',
  },
];

const cocktails = [
  {
    title: 'Orangina Fraise',
    price: '$20',
    tags: 'Fraise | Orange | soda | 30 ml',
  },
  {
    title: "Ngaoisse Carrote",
    price: '$16',
    tags: 'Carrotte | Ginger juce | juce of lime',
  },
  {
    title: 'Rouiba Fraise',
    price: '$10',
    tags: 'Fraise | Citrus juice | Sugar',
  },
  {
    title: 'Texana Bassbasse',
    price: '$31',
    tags: 'Bassbasse | Brown sugar | Taxanna',
  },
  {
    title: 'Texanna Orange',
    price: '$26',
    tags: 'Orange | Sweet taxixo | Other',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

export default { wines, cocktails, awards };
