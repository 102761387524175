import React from 'react';

import{ images } from "../../constants"
import './AboutUs.css';

const AboutUs = () => (
  <div className='app__aboutus app__bg flex__center section__padding' id='about'>
    <div className='app__aboutus-overlay flex__center'>
      <img src={images.G} alt="g letter" />
    </div>

    <div className='app__aboutus-content flex__center'>
      <div className='app__aboutus-content_about'>
        <h1 className='headtext__cormorant'>About Us </h1>
        <img src={images.spoon} alt="about__spoon"/>
        <p className='p__opensans'>
        Let us to give you our best delice ,Algerian food ,traditional from Jijel, Setif,Oran and Algers the 4th best cook in the world in 2023. Come try it in Aboubaker Food Restaurant
        </p>
        <button className='custom__button' type='button'>Read more</button>
      </div>
      <div className=' app__aboutus-content_knife flex__center'>
        <img src={images.knife} alt="about knife"/>
      </div>
      <div className='app__aboutus-content_history'>
        <h1 className='headtext__cormorant'>Our History </h1>
        <img src={images.spoon} alt="about__spoon"/>
        <p className='p__opensans'>
        Let us to give you our best delice ,Algerian food, traditional from Jijel, Setif,Oran and Algers the 4th best cook in the world in 2023. Come try it in Aboubaker Food Restaurant
        </p>
        <button className='custom__button' type='button'>Read more</button>
      </div>
    </div>
  </div>
);

export default AboutUs;
