import React from 'react';
import { BsFillPlayFill, BsPauseFill, bsPauseFill } from "react-icons/bs"
import { meal } from '../../constants';
import './Intro.css';

const Intro = () => {
  const [PlayVideo, setPlayVideo] = React.useState(false)
  const vidRef = React.useRef();
  const handlVideo = () => {
    setPlayVideo((prePlayVideo) => !prePlayVideo);
    if (PlayVideo) {
      vidRef.current.pause();
    } else {
      vidRef.current.play();
    }

  }
  return (
    <div className='app__video'>
      <video 
        src={meal}
        type='video/mp4'
        ref={vidRef}
        loop
        controls={false}
        muted />
      <div className='app__video-overlay flex__center'>
        <div
          className='app__video-overlay_circle flex__center'
          onClick={handlVideo}
        >
          {PlayVideo ? (
            <BsPauseFill color="#FFF" fontSize={30} />)
            : <BsFillPlayFill color="#FFF" fontSize={30} />}

        </div>
      </div>
    </div>
  )
}



export default Intro;
