import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="Try ours best Delices" />
      <h1 className="app__header-h1">The Best way for Dining</h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>Let us to give you our best delice ,Algerian food ,traditional from Jijel, Setif,Oran and Algers the 4th best cook in the world in 2023. Come try it in Aboubaker Food Restaurant</p>
      <button type="button" className="custom__button">Explore our Menu</button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header_img" />
    </div>
  </div>
);

export default Header;
 